import React, { useState } from 'react';
import buttonStyle from '../global/btn';

const VideoMaid = ({ video }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openPopup = () => {
    setIsOpen(true);
  };

  const closeTheVideo = () => {
    setIsOpen(false);
  };

  return (
    <>
      <span
        onClick={openPopup}
        className={buttonStyle}
      >
        Video
      </span>

      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-black bg-opacity-80 fixed inset-0"></div>
          <div className="max-w-screen-lg w-full bg-white p-6 rounded-lg shadow-lg z-50 animate-fade-in relative">
            <button
              className="absolute top-0 right-0 text-red-500 hover:text-red-700 text-4xl cursor-pointer"
              onClick={closeTheVideo}
            >
              &times;
            </button>
            <video src={video} className="w-full rounded-lg" controls></video>
          </div>
        </div>
      )}
    </>
  );
};

export default VideoMaid;
