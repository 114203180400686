import React, { useState, useEffect } from "react";
import "../Allmaids.css";
import { erpApiUrl } from "../global/erpAPI";
import Allmaids from "../AllMaids";
import LogoHomecare from "../../navbar/Logo";


export default function OutsideCounty() {
  const [data, setData] = useState([]);
 

  useEffect(() => {
    fetch(
      `${erpApiUrl}&status[]=pending`
    )
      .then((response) => response.json())
      .then((result) => setData(result.response));
  }, []);

  const filteredData = data.reduce((accumulator, item) => {
    if (item.country_status === "Out Side Country") {
      accumulator.push(item);
    }
    return accumulator;
  }, []);
  
  
  return (
    <>
    <div style={{ marginTop: '12px' , textAlign: 'center'}}>

    <div className="text-5xl font-bold text-center text-gray-900 mb-5">
          <LogoHomecare/>
        </div> 
    
        <h3 style={{color:"#363062"}} className="font-bold" >جميع الخدم خارج الدولة</h3>
   
  </div>

    <div style={{ marginTop: '10px'}}>
    <Allmaids data={filteredData} /> 
    </div>
  </>
  );
}
