import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebookF, faInstagram, faLinkedinIn ,faWhatsapp, faFacebook } from '@fortawesome/free-brands-svg-icons';
import './Footer.css';


const SocialIcons = styled.div`
  display: flex;
  justify-content: center;

  a {
    margin: 10px;
  }
`;

const Footer = () => {
  return (
    <footer className="footer-container">

      <SocialIcons>
          <a href="https://api.whatsapp.com/send/?phone=%2B97192388288&text&type=phone_number&app_absent=0" target="_blank" rel="noreferrer noopener">
            <FontAwesomeIcon icon={faWhatsapp} size="2x" />
          </a>
          <a href="https://www.instagram.com/tadbeer.k/?igshid=YTQwZjQ0NmI0OA%3D%3D" rel="noreferrer noopener" target="_blank">
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </a>
          <a href="https://www.facebook.com/%D8%AA%D8%AF%D8%A8%D9%8A%D8%B1-%D8%AE%D9%88%D8%B1%D9%81%D9%83%D8%A7%D9%86-103748121807915/?ref=pages_you_manage" target="_blank" rel="noreferrer noopener">
            <FontAwesomeIcon icon={faFacebook} size="2x" />
          </a>
        </SocialIcons>

      <div className="map-container">
        <iframe
          title="Tadbeer Home Care Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3605.519370554961!2d56.344898073732075!3d25.353904325338636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ef44f35526b05dd%3A0x59f50711b555f1db!2zVEFEQkVFUiB8INiq2K_YqNmK2LE!5e0!3m2!1sen!2sae!4v1700839903505!5m2!1sen!2sae"
          
          style={{ width: '100%', height: '400px' }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
      <div className="footer-content">
        <div className="footer-section about">
          <h3 className="footer-title">About Us</h3>
          <p className="footer-text">We are a leading company in our industry. Our mission is to provide top-notch services with a focus on customer satisfaction.</p>
        </div>
        <div className="footer-section links">
          <h3 className="footer-title">Quick Links</h3>
          <ul className="footer-links">
            <li><a href="/package1">Tradtional Package</a></li>
            <li><a href="/outside"> MaidsOut side UAE</a></li>
            <li><a href="/package4">Monthly Contact</a></li>
          </ul>
        </div>
        <div className="footer-section contact">
          <h3 className="footer-title">Contact</h3>
          <p><strong>Email:</strong> khormaids@gmail.com</p>
          <p><strong>Phone:</strong> +971 9 238 8288</p>
          <p><strong>Address:</strong> Street - near to Como Cafe - حياوه - إمارة الشارقةّ
الشارقة , خورفكان , حياوة</p>
        </div>
        <div className="footer-section social">
          <h3 className="footer-title">Follow Us</h3>
          <div className="social-icons">
            {/* <a href="https://twitter.com" className="social-icon"><FontAwesomeIcon icon={faTwitter} /></a> */}
            <a href="https://www.facebook.com/%D8%AA%D8%AF%D8%A8%D9%8A%D8%B1-%D8%AE%D9%88%D8%B1%D9%81%D9%83%D8%A7%D9%86-103748121807915/?ref=pages_you_manage" className="social-icon"><FontAwesomeIcon icon={faFacebookF} /></a>
            {/* <a href="https://www.linkedin.com/in/khor-maids-366485221/" className="social-icon"><FontAwesomeIcon icon={faInstagram} /></a> */}
            <a href="https://www.linkedin.com/in/khor-maids-366485221/" className="social-icon"><FontAwesomeIcon icon={faLinkedinIn} /></a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Tadbeer Khorfakan. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
