import { Link } from 'react-router-dom';

import IPhoneFrame from '../hooks/Iphone_frame/IPhoneFrame';

export default function ModernCard({ linkTo, title,  buttonText, photo,bgColor}) {
    return (
        <div style={{backgroundColor:bgColor}} className="w-full md:max-w-2xl lg:max-w-4xl xl:max-w-6xl mx-auto   rounded-3xl overflow-hidden shadow-xl hover:shadow-2xl transition-shadow duration-500 ease-in-out border border-white">

            <Link to={linkTo} className="block p-8">
                <div className="flex justify-between items-center mb-6">
                    <div className="flex items-center  space-x-80">
                        
                        <h2 className=" text-6xl bold   text-white">{title}</h2>
                      
                        
                    </div>
                    <IPhoneFrame imageSrc={photo} className={`text-white h-6 w-6 hover:text-black transition-colors duration-300`} />
                </div>
             
                <div className="flex justify-center">
                    <button className="w-full  rounded-full  bg-yellow-500 text-black text-1xl font-bold py-6 shadow transition-all duration-300">
                        {buttonText}
                    </button>
                </div>
            </Link>
        </div>
    );
}
