import React, { useState } from 'react';
import WhatsAppButton from './Whatsapp';

const WhatsappList = ({ maid }) => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative" onMouseLeave={() => setIsOpen(false)}>

      <button
        className="text-orange-500 hover:text-blue-800 font-semibold underline"
        onClick={togglePopup}
      >
        {`Book ${maid.name}`}
      </button>


      {isOpen && (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-40">
          <div className="bg-white border border-gray-200 shadow-2xl rounded-lg p-6 w-full max-w-lg mx-auto z-50 transition duration-300 ease-out">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold text-gray-700">Choose Your Assistant</h2>
              <button
                className="text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out"
                onClick={togglePopup}
              >
                Close
              </button>
            </div>
            <div className="flex flex-col space-y-4 overflow-auto max-h-96 py-2">
              <WhatsAppButton maid={maid} phoneNumber={"+971504346232"} customer_service_name={"Mohammed Fayez"} />
              <WhatsAppButton maid={maid} phoneNumber={"+971504249939"} customer_service_name={"YASMINA AHMED"} />
              <WhatsAppButton maid={maid} phoneNumber={"+971565252563"} customer_service_name={"RICHEN ABATAYO"} />
              <WhatsAppButton maid={maid} phoneNumber={"+971501685657"} customer_service_name={"BUTHAINA ABDULLAH"} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WhatsappList;
