import React, { useState, useEffect } from "react";
import "../Allmaids.css";
import { erpApiUrl } from "../global/erpAPI";
import Allmaids from "../AllMaids";

import LogoHomecare from "../../navbar/Logo";

export default function Amjad() {
  const [data, setData] = useState([]);
  const [maids] = useState("");

  useEffect(() => {
    fetch(
      `${erpApiUrl}&status[]=approved`
    )
      .then((response) => response.json())
      .then((result) => setData(result.response));
  }, []);

  const filteredData = data.filter((a) => a.maid_type === "" && a.name.toLowerCase().includes(maids.toLowerCase()));

  return (
    <>
      <div style={{ marginTop: '12px' , textAlign: 'center'}}>

      <div className="text-5xl font-bold text-center text-gray-900 mb-5">
          <LogoHomecare/>
        </div>  
      
      <h3 style={{color:"#363062"}} className="font-bold" >خادمات تحت اقامة الكفيل داخل الدولة</h3>
        
    </div>

      <div style={{ marginTop: '10px'}}>
      
      <Allmaids data={filteredData} /> 
      </div>
    </>
   
  );
}
