import React from 'react';
import LogoHomecare from '../navbar/Logo';
import Card from './Card'; 
import Footer from '../hooks/footer/Footer'; 



const styles = `
@media (max-width: 600px) {
  .grid-container {
    display: flex;
    flex-direction: column;
    align-items: center; // Center the cards on mobile view
  }
}
`;

export default function Mainpage() {
  return (
    <>
   <div className="flex justify-center bg-gray-200 min-h-screen">
        <style>{styles}</style>
        <div className="z-10 w-full max-w-6xl mx-auto">
          <div className="text-5xl font-bold text-center text-gray-900 ">
            <LogoHomecare />
          </div>
          <div className="grid justify-center grid-cols-1 gap-10 mb-20  rounded-3xl">
          {/* First Card */}
          <Card
            linkTo="/package1"
            title="الباقة الاولى"
            
            buttonText= "الباقة التقليديه على اقامة الكفيل ,  الخدم المتاح بالصور و السيرة الذاتية" 
            bgColor='#33425B'
            photo="/p1.jpeg"
          />
          {/* Second Card */}
          <Card
            linkTo="/package4"
            title=" الباقة المرنة"
            
            buttonText=" على اقامة تدبير خورفكان عقود شهرية واسبوعية وسنوية   "
            photo="/p4.jpeg"
            bgColor="#474744"

           
          />
          {/* Third Card */}
          <Card
            linkTo="/outside"
            title="خارج دولة الامارات "
            description=" جميع الطلابات خارج الدولة "
            buttonText="   جميع السير الذاتية  خارج دولة الامارات"
            photo="/khorbg.jpeg"
            bgColor="#04536C"
           
          />
        </div>

      </div>
      
    </div>
    <Footer />
    </>
  );
}
