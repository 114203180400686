import React from 'react';
import MaidsCv from './maidsCardsButtons/MaidsCV';
import VideoMaid from './maidsCardsButtons/VideoMaid';
import buttonStyle from './global/btn';
import BigphotoMaidsCV from './maidsCardsButtons/Bigphotomaid';
import PopupButton from './maidsCardsButtons/PopupButton';
import WhatsappList from './maidsCardsButtons/WhatsUpList';




const MaidsCards = ({ data }) => {
  return (
    
    
    <div  className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">

      {data.map((maid, i) => (


        <div style={{backgroundColor:"white"}} className="mb--3 rounded-3xl" key={i}>

          <div  className=" rounded-lg p-4 flex flex-col relative">
            <div className="relative w-full h-80 mb-4 overflow-hidden rounded-lg shadow-md">
              <img
                className="absolute inset-0 w-full h-full object-cover transition transform hover:scale-105 filter brightness-125 hover:brightness-125 hover:shadow-xl rounded-lg border-4 border-white"
                src={maid.img}
                alt={maid.name}
              />
              
                
            
            </div>
            <WhatsappList  maid={maid} />

            

          
              <p className="text-gray-400 text-lg font-semibold mb-2">
                Nationality: <span className="text-silver">{maid.nationality}</span>
              </p>
           
            
            <p className="text-gray-400 text-lg font-semibold">
              Age: <span className="text-silver ml-1">{maid.age}</span>
            </p>
            
            <div className="flex mt-5 items-center space-x-1">
               <MaidsCv
                 maidName={maid.name}
                 nationality={maid.nationality}
                 photo={maid.img}
                 workexp={maid.exp_country}
                 religion={maid.religion}
                 cooking={maid.cooking}
                 washing={maid.washing}
                 cleaning={maid.cleaning}
                 ironing={maid.ironing}
                 salary={maid.monthly_salary}
                 phone={maid.phone}
                 className="flex-1"
             />
                {maid.img2.length > 0 && (
                  <BigphotoMaidsCV photo={maid.img2} className="flex-1" />
                )}

                {maid.video_link.length > 0 && (
                  <VideoMaid video={maid.video_link} className="flex-1" />
                )}
                         
                <a
                  href={`https://khorfakkan.me/frount/profile.php?err=err0&fl=${maid.system_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex-1"
                >
                  <span className={buttonStyle}>
                    Maid CV
                  </span>
                </a>
              </div>

          </div>
        </div>
      ))}
    </div>
  );
};

export default MaidsCards;
