import React, { useState } from "react";
import "./Allmaids.css";
import MaidsCards from "./MaidsCards";

export default function Allmaids({ data }) {
    const nationalities = {
        Filipino: "Philippines",
        Indonesian: "Indonesia",
        Ethiopian: "Ethiopia",
        Myanmar: "Myanmar",
        NebalAnnIndiaSerlinka:'Nepal',
        Ghana :'Ghana',
        Uganda :'Uganda',
        Madagascar: "Madagascar",
        SriLanka: "Sri_Lanka",
        Indian: "Indian",
        Kenya : 'Kenya',
        Tanzania :'Tanzania'

    };

    const [selectedNationality, setSelectedNationality] = useState(null);

    const filterMaids = (nationalityKeys) => {
        return data.filter(maid => {
            const nationalityMatch = Array.isArray(nationalityKeys) ? nationalityKeys.some(key => maid.nationality.includes(key)) : maid.nationality.includes(nationalityKeys);
            return nationalityMatch &&
                !maid.maid_booked?.includes("Booked") &&
                !maid.maid_booked?.includes("Empty") &&
                !maid.maid_booked?.includes("Hold") &&
                !maid.visa_status?.includes("Visa");
        });
    };

    const handleButtonClick = (nationalityKey) => {
        setSelectedNationality(selectedNationality === nationalityKey ? null : nationalityKey);
    };

    const renderButtons = () => {
        return Object.entries(nationalities).map(([key, value]) => {
            const maids = filterMaids(value);
            if (maids.length > 0) {
                return (
                    <button
                        key={key}
                        className={`btn ${selectedNationality === key ? "active" : ""}`}
                        onClick={() => handleButtonClick(key)}
                    >
                        <span className="nationality">{key}</span>
                        <span className="circle">{maids.length}</span>
                    </button>
                );
            }
            return null;
        });
    };

    const renderMaidsCards = () => {
        if (selectedNationality) {
            const maids = filterMaids(nationalities[selectedNationality]);
            if (maids.length > 0) {
                return <MaidsCards data={maids} handleMaidClick={setSelectedNationality} />;
            }
        }
        return <p>Select Nationality.</p>;
    };

    return (
        <div style={{ backgroundColor: '#F0F0F0', borderRadius: '70px' }}>
            <div className="button-container">
                {renderButtons()}
            </div>
            {renderMaidsCards()}
        </div>
    );
}
