

export default function LogoHomecare (){

    return(
      <div className="flex justify-center items-center h-32 mb-[-18px]">
      <img
        className="h-96 w-96"
        src='/Logo.png'
        alt='gg'
      />
    </div>
     
    )
}