import React from 'react';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const WhatsAppButton = ({ maid, phoneNumber, customer_service_name }) => {
  const handleClick = () => {
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(`Selected maid name: ${maid.name} from: ${maid.nationality}`)}`;
    window.open(url, '_blank');
  };

  return (
    <div className="flex items-center justify-center space-x-2 bg-gradient-to-r from-green-400 to-green-600 hover:from-green-500 hover:to-green-700 shadow-lg hover:shadow-xl rounded-lg cursor-pointer p-3 transition-all" onClick={handleClick}>
      
      <FontAwesomeIcon icon={faWhatsapp} className="text-2xl" />
      <p className="text-lg font-semibold">{customer_service_name}</p>
    </div>
  );
};

export default WhatsAppButton;
