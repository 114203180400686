import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Amjad from './hooks/categories/Amjad';
import OutsideCounty from './hooks/categories/Outsidecounty';
import Package4Maids from './hooks/categories/Package4Maids';
import Mainpage from './mainpage/Mainpage';
import './App.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';


function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Mainpage/>} />
          <Route path="/package1" element={<Amjad />} />
          <Route path="/outside" element={<OutsideCounty />} />
          <Route path="/package4" element={<Package4Maids />} />
        </Routes>
        <a href="https://wa.me/+97192388288" className="whatsapp-icon" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faWhatsapp} />
          <span>WhatsApp</span> 
        </a>
      </div>
    </Router>
  );
}

export default App;
