import React from 'react';
import PropTypes from 'prop-types'; // This is for prop type validation
import './iPhoneFrame.css';

const IPhoneFrame = ({ imageSrc }) => {
  return (
    <div className="iphone-container">
      <div className="iphone-frame">
        <div className="iphone-top-notch"></div>
        <div className="iphone-screen">
          {imageSrc && <img src={imageSrc} alt="iPhone Screen" className="iphone-image" />}
        </div>
        <div className="iphone-side"></div>
        <div className="iphone-home-button"></div>
      </div>
    </div>
  );
};

IPhoneFrame.propTypes = {
  imageSrc: PropTypes.string,
};

export default IPhoneFrame;
